<template>   
  <b-card-text>
    <b-row>
      <b-col md="4" v-if="crud.form.ID1">
        <b-form-group label="Cantidad por defecto al agregar producto">
          <b-form-input type="number"
                        step="1"
                        size="sm"
                        v-model="crud.form.ID1.value_number"
                        placeholder="Cantidad por defecto">
          </b-form-input>
        </b-form-group>
      </b-col>     
    </b-row>
    <b-row>
      <b-col md="4" v-if="crud.form.ID2">        
        <FindObject render="search"
                    type="customers" 
                    tag="Cliente por defecto en nuevo comprobante"
                    @select-object="loadCustomers($event)" 
                    :valueID="crud.form.ID2.value_number"
                    :key="crud.form.ID2.value_number"
                    :where="conditionCustomersActive"/>                                   
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">                          
        <b-button variant="dark" size="sm" @click="save()">Guardar</b-button>                        
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col>
        <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
          <i class="fa fa-angle-double-left"></i>
          Volver
        </b-button>                        
      </b-col>      
    </b-row>                       
  </b-card-text>
</template>
<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Session from '@/handler/session'  
  import TabBusiness from '@/components/inc/myBusiness/business'
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    components: {        
      TabBusiness,
      FindObject,
    },    
    data: () => {
      return {              
        crud: { 
          form: {
            ID1: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },
            ID2: {
              id: 0,
              value_string: '',
              value_number: 0,
              value_boolean: false,
            },                                                                                  
          },             
        },
        tabIndex: 0,       
        modulesActive: [],        
      }
    },
    mounted () {      
      this.show()      
    },
    computed: {
      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },          
    },
    methods: {
      // SELECT
      loadCustomers (object) {        
        if(object){          
          this.crud.form.ID2.value_number = object.id             
        } else {          
          this.crud.form.ID2.value_number = 0          
        }
      }, 
              
      show() {   
        var result = serviceAPI.obtenerConfiguracion()

        result.then((response) => {
          var data = response.data
          
          data.forEach(element => {            
            if(element.id == 1)  {
              this.crud.form.ID1.id = element.id
              this.crud.form.ID1.value_number = parseInt(element.value_number)
            }            
            
            if(element.id == 2)  {
              this.crud.form.ID2.id = element.id
              this.crud.form.ID2.value_number = parseInt(element.value_number)
            }                                     
          });          
        })
        .catch(error => {              
          this.$awn.alert(Error.showError(error));
        })        
      },              
      save() {           
        this.$bvModal.msgBoxConfirm('¿Guardar configuración?', {
          title: 'Guardar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {                     
            let loader = this.$loading.show();
            
            var result = null
            result = serviceAPI.saveConfiguracion(this.crud.form.ID1);  
            result = serviceAPI.saveConfiguracion(this.crud.form.ID2);  
            
            result.then((response) => {
              loader.hide()
              this.show()
              this.$awn.success("Configuración guardada");
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },    
    } 
  }
</script>